import axios from 'axios'
import { SYSTEM_RESPONSE_MESSAGE } from './request-enum'
import Cookies from 'js-cookie'
import { Message } from "view-design";
import router from "@/router";
import { encrypt, decrypt } from '@/utils/encryption';
let isEncrypt = process.env.NODE_ENV === 'production'?true:false //是否加密 true 加密 false 不加密
let status = ''
// 创建axios实例
const instance = axios.create({
    timeout: 60000
});
instance.interceptors.request.use(
     (config) => {
         const token = Cookies.get("token") || ""; // 获取本地token
         config.headers["User-Token"] = token; // 在headers里加入token
         // config.headers["User-Encryption"] = 'encryption'
         if(isEncrypt){
             config.headers["User-Encryption"] = 'encryption '
         }
         return config;
    },
     (error) => {
         return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    (response) => {
        if(isEncrypt){
            let data
            status === 'login'?data = response:data = decrypt(response,Cookies.get("token").slice(5,21))
            return status === 'login'?data:{data:JSON.parse(data)}
        }else{
            return response
        }
    },
    (error) => {
        // 返回接口返回的错误信息
        if(error && error.response){
            switch (error.response.status){
                case 400:
                    error.message = SYSTEM_RESPONSE_MESSAGE.REQUEST_ERROR; //请求错误(400)
                    break;
                case 401:
                    error.message = SYSTEM_RESPONSE_MESSAGE.LOGIN_EXPIRE; //登录已过期(401)
                    Message.error('您的登录已经过期，请重新登录！')
                    Cookies.remove('token')
                    router.push('/login').then()
                    break;
                case 403:
                    error.message = SYSTEM_RESPONSE_MESSAGE.NO_ACCESS_RIGHTS; //无访问权限(403)
                    break;
                case 404:
                    error.message = SYSTEM_RESPONSE_MESSAGE.ACCESS_ERROR; //请求出错(404)
                    break;
                case 408:
                    error.message = SYSTEM_RESPONSE_MESSAGE.THE_REQUEST_TIMEOUT; //请求超时(408)
                    break;
                case 500:
                    error.message = SYSTEM_RESPONSE_MESSAGE.SERVER_ERROR; //服务器错误(500)
                    break;
                case 501:
                    error.message = SYSTEM_RESPONSE_MESSAGE.SERVICE_NOT_IMPLEMENTED; //服务未实现(501)
                    break;
                case 502:
                    error.message = SYSTEM_RESPONSE_MESSAGE.NETWORK_ERROR; //网络错误(502)
                    break;
                case 503:
                    error.message = SYSTEM_RESPONSE_MESSAGE.SERVICE_UNAVAILABLE; //服务不可用(503)
                    break;
                case 504:
                    error.message = SYSTEM_RESPONSE_MESSAGE.NETWORK_TIMEOUT; //网络超时(504)
                    break;
                case 505:
                    error.message = SYSTEM_RESPONSE_MESSAGE.THE_HTTP_VERSION_IS_NOT_SUPPORTED; //HTTP版本不受支持(505)
                    break;
                default:
                    error.message = SYSTEM_RESPONSE_MESSAGE.CONNECTION_ERROR+`(${error.response.status})!`; //连接出错 status 未知
            }
        }else {
            error.message = SYSTEM_RESPONSE_MESSAGE.FAILED_TO_CONNECT_TO_SERVER //连接服务器失败
        }
        return Promise.reject(error)
    }
);

export default {

    /**
     * get 请求
     * @param url
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    get( url, params){
        const conf = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        status = ''
        return instance.get(url,params,conf)
    },
    /**
     * delete 请求
     * @param url
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete( url, params){
        status = ''
        return instance.delete(url,params)
    },
    /**
     * post 请求
     * @param url
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    post(url, params,name){
        const conf = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            }
        }
        status = name
        if(isEncrypt){
            let data
            status === 'login'?data = instance.post(url, params,conf):data = instance.post(url, encrypt(params,Cookies.get("token").slice(5,21)),conf);
            return data
        }else{
            return instance.post(url, params,conf)
        }
    },
    /**
     * post 请求， 使用JSON数据方式 调用接口
     * @param url
     * @param data
     * @returns {*}
     */
    postJson( url, params,name){
        const conf = {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        }
        status = name
        if(isEncrypt){
            let data
            name === 'login'?data = instance.post(url, params,conf):data = instance.post(url, encrypt(params,Cookies.get("token").slice(5,21)),conf);
            return data
        }else{
            return instance.post(url, params,conf)
        }
    },
    /**
     * post 请求 上传
     * @param url
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    postUpLoad(url, params){
        const conf = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
        status = ''
        return instance.post(url, params,conf);
    },
    /**
     * post 请求 上传 带进度
     * @param url
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    postUpLoadSpeed(url, params,conf){
        status = ''
        return instance.post(url, params,conf,);
    },
    /**
     * post 请求， 使用blob数据方式 调用接口
     * @param url
     * @param data
     * @returns {*}
     */
    postDownLoad( url, params){
        const conf = {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            responseType: "blob"
        }
        status = ''
        return instance.post(url, params,conf);
    },
}
